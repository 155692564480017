import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import SchedulingIllustration from "../../../svg/ComparisonResultPageIllustrations/schedulingservices .svg";
import AcuitySchedulingSVG from "../../../svg/ComparisonResultGridLogos/schedulingservices/acuityscheduling.svg";
import JobberSVG from "../../../svg/ComparisonResultGridLogos/schedulingservices/jobber.svg";
import ScheduFlowSVG from "../../../svg/ComparisonResultGridLogos/schedulingservices/scheduflow.svg";
import SimplyBookSVG from "../../../svg/ComparisonResultGridLogos/schedulingservices/simplybookme.svg";
import TenToEightSVG from "../../../svg/ComparisonResultGridLogos/schedulingservices/tentoeight.svg";
export const pageMetaData = {
  pageTitle: "Compare Business Scheduling Services",
  pageDescription: "Keep Organized From $10 Per Month",
  pageImagePath: "/schedulingservices.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "Price"
      }, {
        index: 2,
        text: "Free Trail"
      }, {
        index: 3,
        text: "Number Of Users"
      }, {
        index: 4,
        text: "Customer Support"
      }, {
        index: 5,
        text: "Platform"
      }, {
        index: 6,
        text: "Pros"
      }, {
        index: 7,
        text: "Training"
      }],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Acuity Scheduling",
          subText: "",
          imageKey: "acuity"
        }
      }, {
        index: 2,
        text: "15",
        isPriceCell: true,
        showRating: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        rating: 4
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "1 - 49"
      }, {
        index: 5,
        text: "Online During Business Hours"
      }, {
        index: 6,
        text: "Web, iOS, Android"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Automate your client bookings, cancellations, reminders & payments using the worlds friendliest online scheduling software."
      }, {
        index: 8,
        text: "Webinars, Knowledge Base"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://acuity.jnqsge.net/c/1323808/722903/10639",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Acuity Scheduling",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Jobber",
          subText: "",
          imageKey: "jobber"
        }
      }, {
        index: 2,
        text: "29",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "1 - 49"
      }, {
        index: 5,
        text: "Online During Business Hours"
      }, {
        index: 6,
        text: "Web, iOS, Android"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Jobber is perfect for established and growing home service businesses who need better organization, mobile access, and more efficient scheduling and billing processes."
      }, {
        index: 8,
        text: "In Person, Online, Knowledge Base"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://getjobber.com/dlp/fsm-software/?utm_medium=cpc&utm_campaign=appointment-scheduling&utm_content=visit_website_button&utm_source=capterra",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Jobber",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "ScheduFlow",
          subText: "",
          imageKey: "scheduflow"
        }
      }, {
        index: 2,
        text: "29.95",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "1 - 1000 +"
      }, {
        index: 5,
        text: "Online During Business Hours"
      }, {
        index: 6,
        text: "Windows, Web, Android"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "ScheduFlow is the only cloud based solution here that you can download as an application on your desktop. With ScheduFlow you can manage your appointments and employees all in one place."
      }, {
        index: 8,
        text: "Live Online, Knowledge Base"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.scheduflow.com/Account/DeviceSetup",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to ScheduFlow",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "SimplyBook.me",
          subText: "",
          imageKey: "simplybookme"
        }
      }, {
        index: 2,
        text: "10",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "1 - 1000+"
      }, {
        index: 5,
        text: "27/7 Online"
      }, {
        index: 6,
        text: "Web, iOS, Android"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "SimplyBook.me suits all service companies which accept bookings on a timely basis like Hair salons, SPAs, Schools, Event management, Gyms, Private trainers, Consultants, Financial advisors and so on."
      }, {
        index: 8,
        text: "Live Online Webinars"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://simplybook.me/en/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to SimplyBook.me",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "10to8",
          subText: "",
          imageKey: "tentoeight"
        }
      }, {
        index: 2,
        text: "12",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "1 - 1000+"
      }, {
        index: 5,
        text: "Online During Business Hours"
      }, {
        index: 6,
        text: "Web, iOS, Android"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Businesses of all sizes regardless of sector or industry. We have clients in banking, healthcare, education, customer service, sales, beauty, and even alpaca farming."
      }, {
        index: 8,
        text: "In Person, Online, Webinars"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://10to8.com/free-scheduling-software/?utm_medium=cpc&utm_campaign=appointment-scheduling&utm_source=capterra",
        openLinkExternally: false,
        text: "Sign Up",
        title: "Click to sign up to 10to8",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare business scheduling services`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<SchedulingIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="SchedulingIllustration" />, <SchedulingIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="SchedulingIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`Waiting on copy from Steady Content as I can't think of anything relevant to say so saying nothing at all is probably best.`}</p>
      </ComparisonResultPageTextWithImageSection>
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <AcuitySchedulingSVG className="w-full" imagekey="acuity" mdxType="AcuitySchedulingSVG" />
  <JobberSVG className="w-full" imagekey="jobber" mdxType="JobberSVG" />
  <ScheduFlowSVG className="w-full" imagekey="scheduflow" mdxType="ScheduFlowSVG" />
  <SimplyBookSVG className="w-full" imagekey="simplybookme" mdxType="SimplyBookSVG" />
  <TenToEightSVG className="w-full" imagekey="tentoeight" mdxType="TenToEightSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="business" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`How We Ranked`}</h2>
        <h3>{`Comparing Services by Their Ratings`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="scheduling services" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      